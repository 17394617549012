import styled, { css } from 'styled-components'

import { Container } from 'components/blocks/sections/directories/AllEntries/ServiceEntries'
import { Button, Tag } from 'components/ui'
import { Text } from 'components/ui/deprecated/Text'
import { ClinicSearchHeroStoryblok, HeroStoryblok } from 'lib/storyblok/types'
import { getAnchorFromCmsLink, textByLine } from 'lib/utils/content'

import { ButtonGroup } from '../../ui/deprecated/ButtonGroup'
export type HeroVariants = HeroStoryblok['variant']
type Props = {
  block: HeroStoryblok | ClinicSearchHeroStoryblok
}

export const CommonHero: React.FC<Props> = ({ block }) => {
  const {
    headline,
    tagline,
    primary_button_text,
    primary_button_link,
    secondary_button_text,
    secondary_button_link,
    tag_text,
  } = block

  const hasPrimaryButton =
    primary_button_text &&
    primary_button_link &&
    getAnchorFromCmsLink(primary_button_link).href
  const hasSecondaryButton =
    secondary_button_text &&
    secondary_button_link &&
    getAnchorFromCmsLink(secondary_button_link).href

  return (
    <>
      {tag_text && <Tag className="mb-6">{tag_text}</Tag>}

      <Headline as="h1" variant="display">
        {textByLine(headline, (part, i, length) => {
          return (
            <>
              {part}
              {i + 1 < length && <br />}
            </>
          )
        })}
      </Headline>

      {tagline &&
        textByLine(tagline, (line) => (
          <Tagline as="p" variant="title/small/regular">
            {line}
          </Tagline>
        ))}

      <ButtonsHolder inlineFrom="sm">
        {hasPrimaryButton && primary_button_link && (
          <Button
            as="a"
            rel={getAnchorFromCmsLink(primary_button_link).rel}
            href={getAnchorFromCmsLink(primary_button_link).href}
            target={getAnchorFromCmsLink(primary_button_link).target}
            variant="primary"
          >
            {primary_button_text}
          </Button>
        )}

        {hasSecondaryButton && secondary_button_link && (
          <Button
            as="a"
            rel={getAnchorFromCmsLink(secondary_button_link).rel}
            href={getAnchorFromCmsLink(secondary_button_link).href}
            target={getAnchorFromCmsLink(secondary_button_link).target}
            variant="outline"
          >
            {secondary_button_text}
          </Button>
        )}
      </ButtonsHolder>
    </>
  )
}

const Headline = styled(Text)`
  &:last-of-type {
    padding-bottom: 1.25rem;
  }
`

const Tagline = styled(Text)`
  max-width: 41.875rem;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  opacity: 0.8;
`

const ButtonsHolder = styled(ButtonGroup)`
  padding-top: 2rem;
`

export const Wrapper = styled.section<{ variant: HeroVariants }>`
  position: relative;
  display: flex;
  overflow: hidden;

  ${({ variant }) => {
    const variants = {
      center: css`
        flex-direction: column;
        align-items: center;
        text-align: center;
      `,
      'center-inverted': css`
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
      `,
      side: css`
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        ${({ theme }) => theme.media.md} {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      `,
      background: css`
        flex-direction: column;

        ${({ theme }) => theme.media.md} {
          align-items: center;
          text-align: center;
        }
      `,
    }

    return variants[variant || 'center']
  }}
`

export const ContentHolder = styled(Container)<{ heroVariant: HeroVariants }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: inherit;

  width: 100%;
  max-width: 56.25rem;

  padding: 2rem 1rem;

  z-index: ${({ theme }) => theme.zIndex.low + 1};

  ${({ heroVariant }) =>
    heroVariant === 'side' &&
    css`
      max-width: 44rem;
      margin-right: 0;
      align-items: flex-start;

      ${({ theme }) => theme.media.md} {
        width: 50%;
      }
    `}

  ${({ theme }) => theme.media.md} {
    padding: 2rem;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 5rem;
  }
`
