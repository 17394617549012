import { HeroStoryblok } from 'lib/storyblok/types'

import { CommonHero, ContentHolder, Wrapper } from './CommonHero'
import { ImageHero } from './ImageHero'

type Props = {
  block: HeroStoryblok
}

const Hero: React.FC<Props> = ({ block, ...props }) => {
  const { variant } = block

  return (
    <Wrapper variant={variant} {...props}>
      <ContentHolder heroVariant={variant}>
        <CommonHero block={block} />
      </ContentHolder>
      <ImageHero block={block} />
    </Wrapper>
  )
}

export { Hero }
